<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="homeDoctorItems"
      class="mt-1"
      item-key="id"
      show-select
      single-select
      hide-default-footer
    >
      <template v-slot:item.belongToArea="{ item }">
        {{ item.ptCountry }}-{{ item.ptArea }}-{{ item.ptVillage }}-{{ item.ptGroup }}
      </template>
    </v-data-table>
    <v-pagination
      v-model="queryParams.params.page"
      :length="queryParams.params.pageCount"
    />
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  import bus from '@/services/bus-event';

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },
    data () {
      return {
        selected: [],
        dialogEquipmentList: false,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        },
        homeDoctorItems: [],
        institutionSelected: []
      }
    },
    computed: {
      ...mapGetters({
        homeDoctors: 'homeDoctor/homeDoctors'
      }),
      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.user.realName'),
            align: 'start',
            sortable: false,
            value: 'realName',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.phone'),
            align: 'start',
            sortable: false,
            value: 'phone',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.username'),
            align: 'start',
            sortable: false,
            value: 'username',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.homeDoctorQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
              this.homeDoctorItems = this.homeDoctors
            })
         }
      },

      selected: {
        handler: function(newValue) {
          bus.emit('home-doctor-change', newValue)
        }
      }
    },

    created () {
    },

    mounted () {
      bus.on('institution-change', this.onInstitutionChange);
    },

    beforeDestroy () {
      bus.off('institution-change', this.onInstitutionChange);
    },

    methods: {
      ...mapActions ({
        homeDoctorQuery: 'homeDoctor/query'
      }),

      onInstitutionChange (data) {
        this.institutionSelected = data
        if ( this.institutionSelected.length > 0 ) {
          this.queryParams.data = { depId: this.institutionSelected[0].id }
          let params = Object.assign({}, this.queryParams.params)
          params.page = 0
          this.homeDoctorQuery({params: params, data: this.queryParams.data }).then( (res) => {
            this.queryParams.params.pageCount = res.totalPages
            this.homeDoctorItems = this.homeDoctors
          })
        } else {
          this.selected = []
          this.homeDoctorItems = []
        }
      },

      handleDistribute  () {
        this.dialogEquipmentList = true
      },

      handleSearchFormSubmit(data) {
        if ( this.institutionSelected.length > 0 ) {
          this.queryParams.data = data
          this.queryParams.data.depId = this.institutionSelected[0].id
          let params = Object.assign({}, this.queryParams.params)
          params.page = 0
          this.homeDoctorQuery({params: params, data }).then( (res) => {
            this.queryParams.params.pageCount = res.totalPages
            this.homeDoctorItems = this.homeDoctors
          })
        } else {
          console.log('=====')
        }
      }
    }
  }
</script>
